.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 33.34%;
  height: 33.34%;
  background-color: #dfdfdf;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.image-container {
  width: 35%;
  max-width: 35%;
  overflow: hidden;
  display: inline-flex;
}

.img {
  width: 100%;
}

@media only screen and (max-width: 1366px) {
  .image-container {
    width: 40%;
    max-width: 40%;
  }

  .img {
    width: 100%;
  }
}

@media only screen and (max-width: 1280px) {
  .image-container {
    width: 255px;
    max-width: 40%;
    object-fit: cover;
  }

  .img {
    object-fit: fill;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    max-height: 104px;
    min-height: 104px;
    width: 100vw;
    overflow: hidden;
  }
}

.img {
  /* width: 255px; */
  object-fit: cover;
}

.card {
  width: 70%;
  padding: 32px;
  position: relative;
}

.content {
  width: 90%;
}

.learn_more-icon {
  width: 32px;
}

@media only screen and (max-width: 600px) {
  .image-container {
    width: 108px;
  }
  .img {
    width: 100%;
    object-fit: cover;
  }

  .card {
    /* width: 100%; */
    padding: 16px 12px;
    position: relative;
  }
}

.locale {
  padding: 4px 8px;
  display: inline-block;
  width: auto;
  background: rgba(44, 57, 65, 0.1);
  border-radius: 12px;
  margin-bottom: 8px;
}

.learn_more {
  position: absolute;
  bottom: 32px;
  right: 32px;
}

@media only screen and (max-width: 600px) {
  .learn_more {
    bottom: 10px;
    right: 10px;
  }

  .learn_more-icon {
    width: 28px;
  }
}

@media only screen and (min-width: 1600px) {
  .learn_more {
    bottom: 32px;
    right: 46px;
  }

  .locale {
    margin-bottom: 12px;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #233237;
  margin-bottom: 16px;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #233237;
}

@media only screen and (max-width: 600px) {
  .locale {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .title {
    width: 85%;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .text {
    display: none;
  }
}

.link {
  text-decoration: none;
}
